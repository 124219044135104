
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {


  static targets = ["flashMessagesContainer"]

  closeMessage(event) {
    event.target.closest('.flash-message').remove();
  }



}
