import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["textarea"]

  connect() {
    document.addEventListener("turbo:submit-start", this.resetForm)
  }

  disconnect() {
    document.removeEventListener("turbo:submit-start", this.resetForm)
  }

  resetForm = () => {
    this.element.reset()
  }

  onKeydown = (event) => {
    // If enter key is pressed, submit the form.

    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      this.element.requestSubmit()
    }
  }
}
